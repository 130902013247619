import { FC } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { Contact, ScheduledContactStatus, getContactUnavailability } from "utils/accounts";
import Highlighter from 'react-highlight-words';
import { formatDate } from "utils/utils";
import EditIcon from "assets/EditIcon";

interface IProps {
  search?: string,
  contactStatus: ScheduledContactStatus,
  contact?: Contact,
  onDelete?: () => void,
  onEdit?: () => void,
}

const ContactStatusAlert: FC<IProps> = ({ contactStatus, contact, search = '', onEdit, onDelete }) => {
  const currentUnavailability = getContactUnavailability(contactStatus, contact)
  return (
    <Box width="100%">
      {contactStatus.changeStatusTo === 'Yes' &&
        <Box
          sx={{
            p: '12px',
            width: 'calc(100% - 24px - 2px)',
            border: '1px solid rgba(68, 140, 61, 0.6)',
            borderRadius: '5px',
            backgroundColor: 'rgba(68, 140, 61, 0.2)',
            position: 'relative',
          }}
        >
          {onEdit &&
            <IconButton
              sx={{ p: 0, position: 'absolute', right: 8, top: 10 }}
              onClick={onEdit}
            >
              <Tooltip title="Update Alert">
                <EditIcon sx={{ fontSize: '18px' }} />
              </Tooltip>
            </IconButton>
          }
          {onDelete &&
            <IconButton
              sx={{ p: 0, position: 'absolute', right: 30, top: 10 }}
              onClick={onDelete}
            >
              <Tooltip title="Delete Alert">
                <Delete sx={{ fontSize: '18px' }} />
              </Tooltip>
            </IconButton>
          }
          <Typography style={{ fontSize: '12px', fontWeight: 600 }}>
            {formatDate(contactStatus.applyStatusOn, 'MM/DD/YYYY h:mm A')}
            {' - '}
            {contactStatus.revertStatusOn ? formatDate(contactStatus.revertStatusOn, 'MM/DD/YYYY h:mm A') : 'UFN'}
          </Typography>
          <Typography style={{ fontSize: '12px' }}>Taking Calls</Typography>
          {contactStatus.doNotConnect &&
            <Typography style={{ fontSize: '12px' }}>
              <Highlighter
                searchWords={[search.toLowerCase()]}
                autoEscape={true}
                textToHighlight={`Do not connect: ${contactStatus.doNotConnect.split(';').join(',')}`}
              />
            </Typography>
          }
          {contactStatus.statusNote &&
            <Typography style={{ fontSize: '12px' }}>
              <Highlighter
                searchWords={[search.toLowerCase()]}
                autoEscape={true}
                textToHighlight={`Note: ${contactStatus.statusNote}`}
              />

            </Typography>
          }
        </Box>
      }

      {contactStatus.changeStatusTo !== 'Yes' &&
        <Box
          sx={{
            p: '12px',
            width: 'calc(100% - 24px - 2px)',
            border: '1px solid rgba(232, 11, 11, 0.6)',
            borderRadius: '5px',
            backgroundColor: 'rgba(232, 11, 11, 0.2)',
            position: 'relative',
          }}
        >
          {onEdit &&
            <IconButton
              sx={{ p: 0, position: 'absolute', right: 8, top: 10 }}
              onClick={onEdit}
            >
              <Tooltip title="Update Alert">
                <EditIcon sx={{ fontSize: '18px' }} />
              </Tooltip>
            </IconButton>
          }
          {onDelete &&
            <IconButton
              sx={{ p: 0, position: 'absolute', right: 30, top: 10 }}
              onClick={onDelete}
            >
              <Tooltip title="Delete Alert">
                <Delete sx={{ fontSize: '18px' }} />
              </Tooltip>
            </IconButton>
          }
          {contactStatus.applyStatusOn &&
            <Typography style={{ fontSize: '12px', fontWeight: 600 }}>
              {formatDate(contactStatus.applyStatusOn, 'MM/DD/YYYY h:mm A')}
              {' - '}
              {contactStatus.revertStatusOn ? formatDate(contactStatus.revertStatusOn, 'MM/DD/YYYY h:mm A') : 'UFN'}
            </Typography>
          }
          <Typography style={{ fontSize: '12px' }}>
            <Highlighter
              searchWords={[search.toLowerCase()]}
              autoEscape={true}
              textToHighlight={currentUnavailability}
            />
          </Typography>
          {contactStatus.connectOnly &&
            <Typography style={{ fontSize: '12px' }}>
              <Highlighter
                searchWords={[search.toLowerCase()]}
                autoEscape={true}
                textToHighlight={`Connect only: ${contactStatus.connectOnly.split(';').join(',')}`}
              />

            </Typography>
          }
          {contactStatus.statusNote &&
            <Typography style={{ fontSize: '12px' }}>
              <Highlighter
                searchWords={[search.toLowerCase()]}
                autoEscape={true}
                textToHighlight={`Note: ${contactStatus.statusNote}`}
              />
            </Typography>
          }
        </Box>
      }
    </Box>
  )
}

export default ContactStatusAlert;
