import * as yup from 'yup';
import { AddCircle, Check, Circle, Close, KeyboardArrowDown } from "@mui/icons-material";
import {
  Badge,
  Box, Button, Checkbox, Chip, Collapse, Dialog, FormControlLabel, FormHelperText, IconButton,
  InputAdornment,
  ListItemButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setContactForEditStatus, setContactForStatusList, setSnackbar, SnackbarSeverity } from "reducers/slices/UIReducer";
import { useEffect, useMemo, useState } from "react";
import { cloneDeep } from "lodash";
import { DateTimePicker } from "@mui/x-date-pickers";
import AnimatedButton from "components/buttons/AnimatedButton";
import { createScheduledStatus, updateCurrentAlert, updateScheduledStatus } from 'api/contacts';
import parseYupErrors from 'utils/parseYupErrors';
import { useAppSelector } from 'reducers/hooks';
import { updateContact } from 'reducers/slices/accountPanelReducer';
import { getContactDetail } from 'api/accounts';
import moment from 'moment-timezone';
import { ConflictingContactStatus, getContactName } from 'utils/accounts';
import ContactStatusConflictDialog from './ContactStatusConflictDialog';

const OPTIONS = [
  { value: 'available', label: 'Available', color: '#448C3D' },
  { value: 'unavailable', label: 'Unavailable', color: '#E80B0B' },
]

const UNAVAILABILITY_OPTIONS = [
  'Unavailable',
  'In a meeting',
  'With a client',
  'On the other line',
  'Out of the office',
  'Other',
]

const formSchema = yup.object().shape({
  availability: yup.string().required(),
  startTime: yup.mixed().label('Start Time')
    .test('start-time-before', 'Start Time must be after current time.',
      (value, testContext) => testContext.parent.isContact || !value || value.isAfter(moment())
    )
    .test('start-time-required', 'Start Time is a required field.',
      (value, testContext) => testContext.parent.isContact || !!value
    ),
  endTime: yup.mixed().label('End Time')
    .test('end-time-before', 'End Time must be after current time.',
      (value, testContext) => testContext.parent.untilFurtherNotice || !value || value.isAfter(moment())
    )
    .test('end-time-after', 'End Time must be after start time.',
      (value, testContext) => testContext.parent.untilFurtherNotice || !value || !testContext.parent.isContact || value.isAfter(moment())
    )
    .test('end-time-required', 'End Time is a required field.',
      (value, testContext) => !!value || testContext.parent.untilFurtherNotice
    ),
  untilFurtherNotice: yup.boolean(),

  dontConnectCallers: yup.array().of(yup.string()),
  connectCallers: yup.array().of(yup.string()),
  unavailabilityExplanations: yup.string(),
  unavailabilityExplanationsOther: yup.string()
    .test('other-required', 'How would you want us to explain your unavailability? (Other) is a required field.',
      (value, testContext) => testContext.parent.availability !== 'unavailable' || testContext.parent.unavailabilityExplanations !== 'Other' || !!value
    ),

  additionalNote: yup.string(),
})

type FormState = {
  isNew: boolean,
  isContact: boolean,
  availability: string,
  startTime: Date | null,
  endTime: Date | null,
  untilFurtherNotice: boolean,

  dontConnectCallers: string[],
  connectCallers: string[],
  unavailabilityExplanations: string,
  unavailabilityExplanationsOther: string,

  additionalNote: string,
}

const DEFAULT_STATE: FormState = {
  isNew: false,
  isContact: false,
  availability: 'available',
  startTime: null,
  endTime: null,
  untilFurtherNotice: false,

  dontConnectCallers: [],
  connectCallers: [],
  unavailabilityExplanations: 'Unavailable',
  unavailabilityExplanationsOther: '',

  additionalNote: '',
}

const ContactStatusDialog = () => {
  const [formState, setFormState] = useState<FormState>(cloneDeep(DEFAULT_STATE))
  const [errors, setErrors] = useState<Partial<Record<keyof FormState, string>>>({})
  const [dontConnectCaller, setDontConnectCaller] = useState<string>('')
  const [connectCaller, setConnectCaller] = useState<string>('')
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [conflictingStatuses, setConflictingStatuses] = useState<ConflictingContactStatus[] | null>(null)
  const contactForEditStatus = useAppSelector(state => state.ui.contactForEditStatus)
  const contactForStatusList = useAppSelector(state => state.ui.contactForStatusList)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!contactForEditStatus) {
      setFormState(cloneDeep(DEFAULT_STATE))
      setDontConnectCaller('')
      setConnectCaller('')
      setShowAdvanced(false)
    } else if (contactForEditStatus.contactStatus) {
      const contactStatus = contactForEditStatus.contactStatus

      if (contactStatus.id === 'new') {
        setFormState({
          ...cloneDeep(DEFAULT_STATE),
          isNew: true,
          isContact: false,
        })
        return
      }

      setFormState({
        isNew: false,
        isContact: contactStatus.id === 'contact',
        availability: contactStatus.changeStatusTo === 'No' ? 'unavailable' : 'available',
        // @ts-ignore
        startTime: contactStatus.applyStatusOn ? moment(contactStatus.applyStatusOn) : null,
        // @ts-ignore
        endTime: contactStatus.revertStatusOn ? moment(contactStatus.revertStatusOn) : null,
        untilFurtherNotice: !contactStatus.revertStatusOn,

        dontConnectCallers: contactStatus.doNotConnect ? contactStatus.doNotConnect.split(';') : [],
        connectCallers: contactStatus.connectOnly ? contactStatus.connectOnly.split(';') : [],
        unavailabilityExplanations: contactStatus.unavailabilityExplanation || 'Unavailable',
        unavailabilityExplanationsOther: contactStatus.unavailabilityExplanationOther || '',

        additionalNote: contactStatus.statusNote || '',
      })

      if (contactStatus.changeStatusTo === 'Yes' && contactStatus.doNotConnect) {
        setShowAdvanced(true)
      }

      if (contactStatus.changeStatusTo === 'No' &&
        (contactStatus.connectOnly ||
        (contactStatus.unavailabilityExplanation && contactStatus.unavailabilityExplanation !== 'Unavailable') ||
        contactStatus.unavailabilityExplanationOther)
      ) {
        setShowAdvanced(true)
      }
    }
  }, [contactForEditStatus])

  const minDateTime = useMemo(() => {
    return moment().add(5, 'minutes')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactForEditStatus])

  const onChange = (prop: keyof FormState) => (value: any) => {
    setFormState((oldState) => ({
      ...oldState,
      [prop]: value,
    }))
  }

  const onCallerKeyDown = (type: 'dontConnectCallers' | 'connectCallers') => (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      if (type === 'dontConnectCallers') {
        addDontConnectCaller()
      } else {
        addConnectCaller()
      }
    }
  }

  const addDontConnectCaller = () => {
    const newValue = dontConnectCaller.trim()
    if (newValue) {
      if (!formState.dontConnectCallers.includes(newValue)) {
        setFormState((oldState) => ({
          ...oldState,
          dontConnectCallers: oldState.dontConnectCallers.concat([newValue]),
        }))
      }
    }

    setDontConnectCaller('')
  }

  const addConnectCaller = () => {
    const newValue = connectCaller.trim()
    if (newValue) {
      if (!formState.connectCallers.includes(newValue)) {
        setFormState((oldState) => ({
          ...oldState,
          connectCallers: oldState.connectCallers.concat([newValue]),
        }))
      }
    }

    setConnectCaller('')
  }

  const onChipDelete = (prop: 'dontConnectCallers' | 'connectCallers', value: string) => {
    setFormState((oldState) => ({
      ...oldState,
      [prop]: oldState[prop].filter(e => e !== value),
    }))
  }

  const handleClose = () => {
    dispatch(setContactForEditStatus(null))
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    setErrors({})

    formSchema
      .validate(formState, { abortEarly: false })
      .then(() => {
        const body = {
          changeStatusTo: formState.availability === 'available' ? 'Yes' : 'No',
          applyStatusOn: formState.startTime,
          revertStatusOn: formState.untilFurtherNotice ? undefined : formState.endTime,

          doNotConnect: formState.availability === 'available' ? formState.dontConnectCallers.join(';') : '',
          connectOnly: formState.availability === 'unavailable' ? formState.connectCallers.join(';') : '',
          unavailabilityExplanation: formState.availability === 'unavailable' ? formState.unavailabilityExplanations : '',
          unavailabilityExplanationOther: formState.availability === 'unavailable' && formState.unavailabilityExplanations === 'Other' ? formState.unavailabilityExplanationsOther : '',
          statusNote: formState.additionalNote,
        }

        let message = ''
        const callback = () => {
          dispatch(setSnackbar({ message: `Contact Call Status ${message}!`, severity: SnackbarSeverity.SUCCESS }))
          dispatch(setContactForEditStatus(null))
          if (contactForEditStatus) {
            getContactDetail(contactForEditStatus.contact.id).then((res) => {
              if (contactForStatusList) {
                dispatch(setContactForStatusList({
                  ...contactForStatusList,
                  ...res.data
                }))
              }
              dispatch(updateContact({
                id: res.data.id,
                update: res.data,
              }))
            })
          }
        }

        const errorCallback = (err: any) => {
          if (err.response.status === 409) {
            setConflictingStatuses(err.response.data.conflict)
          } else {
            dispatch(setSnackbar({ message: err.message, severity: SnackbarSeverity.ERROR })) 
          }
        }

        if (formState.isNew) {
          message = 'created'
          createScheduledStatus(contactForEditStatus?.contact.accountId || '', contactForEditStatus?.contact.id || '', body)
            .then(callback)
            .catch(errorCallback)
            .finally(() => {
              setIsSubmitting(false)
            })
          
          return
        }

        if (formState.isContact) {
          message = 'updated'
          updateCurrentAlert(
            contactForEditStatus?.contact.accountId || '',
            contactForEditStatus?.contact.id || '',
            {
              ...body,
              applyStatusOn: null,
              takeCalls: body.changeStatusTo,
              changeStatusTo: undefined,
            }
          ).then(callback).catch(errorCallback).finally(() => {
            setIsSubmitting(false)
          })
        } else {
          message = 'updated'
          updateScheduledStatus(
            contactForEditStatus?.contactStatus.id || '',
            contactForEditStatus?.contact.accountId || '',
            contactForEditStatus?.contact.id || '',
            body
          ).then(callback).catch(errorCallback).finally(() => {
            setIsSubmitting(false)
          })
        }
      })
      .catch((err: yup.ValidationError) => {
        setErrors(parseYupErrors(err))
        setIsSubmitting(false)
      })
  }

  const conflictCallback = (status: string) => {
    setConflictingStatuses(null)
    dispatch(setSnackbar({ message: `Contact Call Status ${status}!`, severity: SnackbarSeverity.SUCCESS }))
    dispatch(setContactForEditStatus(null))
    if (contactForEditStatus) {
      getContactDetail(contactForEditStatus.contact.id).then((res) => {
        if (contactForStatusList) {
          dispatch(setContactForStatusList({
            ...contactForStatusList,
            ...res.data
          }))
        }
        dispatch(updateContact({
          id: res.data.id,
          update: res.data,
        }))
      })
    }
  }

  const showBadge = !showAdvanced && (
    (formState.availability === 'available' && !!formState.dontConnectCallers.length) ||
    (formState.availability === 'unavailable' && (!!formState.connectCallers.length || (!!formState.unavailabilityExplanations && formState.unavailabilityExplanations !== 'Unavailable'))) ||
    !!formState.additionalNote
  )

  return (
    <Dialog open={!!contactForEditStatus} onClose={handleClose}>
      <ContactStatusConflictDialog
        contactStatuses={conflictingStatuses}
        contact={contactForEditStatus?.contact}
        submitCallback={conflictCallback}
        onClose={() => setConflictingStatuses(null)}
      />

      <Box width={450} px={4} py={3}>
        <Box position="relative">
          <Typography sx={{ fontWeight: 600, fontSize: 20, pr: 4 }}>
            {contactForEditStatus ? getContactName(contactForEditStatus.contact) : ''}
            {` - ${formState.isNew ? 'New' : 'Edit'} Call Status`}
          </Typography>
          <IconButton sx={{ position: 'absolute', right: -4, top: -4 }} onClick={handleClose}>
            <Close sx={{ color: 'black' }} />
          </IconButton>
        </Box>

        <Box
          sx={{
            mt: 2, p: 1,
            backgroundColor: 'rgb(242, 242, 242)',
            borderRadius: '5px',
          }}
        >
          {OPTIONS.map(option => (
            <ListItemButton
              key={option.value}
              sx={{ px: 1 }}
              onClick={() => onChange('availability')(option.value)}
            >
              <Circle sx={{ color: option.color, mr: 2, fontSize: '16px' }} />
              <Typography style={{ fontWeight: 600 }}>{option.label}</Typography>
              {option.value === formState.availability && <Check sx={{ marginLeft: 'auto' }} />}
            </ListItemButton>
          ))}
          {errors.availability && <FormHelperText error>Please choose an availability.</FormHelperText>}
        </Box>

        <Collapse in={!formState.isContact}>
          <Box>
            <Typography mt={1} sx={{ fontSize: '14px', fontWeight: 600 }}>Start Time</Typography>
            <DateTimePicker
              sx={{ mt: 1, width: '100%' }}
              onChange={onChange('startTime')}
              value={formState.startTime}
              slotProps={{
                textField: {
                  size: 'small',
                  InputProps: {
                    sx: { bgcolor: '#E4ECFC' },
                  }
                },
                openPickerIcon: {
                  sx: { color: '#A5B5E3', '&:hover': { color: '#1033A5' } }
                },
              }}
              // @ts-ignore it uses moment time 
              minDateTime={minDateTime}
              closeOnSelect={false}
            />
            {errors.startTime && <FormHelperText error>{errors.startTime}</FormHelperText>}
          </Box>
        </Collapse>

        <Typography mt={1} sx={{ fontSize: '14px', fontWeight: 600 }}>End Time</Typography>
        <Collapse in={!formState.untilFurtherNotice}>
          <Box>
            <DateTimePicker
              sx={{ mt: 1, width: '100%' }}
              onChange={onChange('endTime')}
              value={formState.endTime}
              slotProps={{
                textField: {
                  size: 'small',
                  InputProps: {
                    sx: { bgcolor: '#E4ECFC' },
                  }
                },
                openPickerIcon: {
                  sx: { color: '#A5B5E3', '&:hover': { color: '#1033A5' } }
                },
              }}
              // @ts-ignore it uses moment time 
              minDateTime={
                formState.isContact
                  ? minDateTime
                  : formState.startTime ?? minDateTime
              }
              disabled={formState.untilFurtherNotice}
              closeOnSelect={false}
            />
            {errors.endTime && <FormHelperText error>{errors.endTime}</FormHelperText>}
          </Box>
        </Collapse>

        <FormControlLabel
          sx={{ mt: 1 }}
          label="Until further notice"
          control={
            <Checkbox
              sx={{ mr: '4px' }}
              checked={formState.untilFurtherNotice}
              onChange={(e) => onChange('untilFurtherNotice')(e.target.checked)}
            />
          }
        />

        <Stack alignItems="center" sx={{ mt: 2 }}>
          <Button
            endIcon={
              <Badge
                variant="dot"
                color="primary"
                invisible={!showBadge}
              >
                <KeyboardArrowDown
                  sx={{
                    color: 'rgb(95, 39, 203)',
                    rotate: showAdvanced ? '-180deg' : '0deg',
                    transition: 'rotate 0.3s',
                  }}
                />
              </Badge>
            }
            onClick={() => setShowAdvanced(!showAdvanced)}
            disableElevation
            size="small"
            sx={{
              width: 200,
              color: 'rgb(95, 39, 203)',
            }}
          >
            Advanced Settings
          </Button>
        </Stack>

        <Collapse in={showAdvanced && formState.availability === 'available'}>
          <Box>
            <Typography mt={1} sx={{ fontSize: '14px', fontWeight: 600 }}>Don't connect the following callers</Typography>
            <FormHelperText
              error={formState.dontConnectCallers.length >= 10}
              sx={{ mt: '-4px' }}
            >
              You can add up to 10 callers
            </FormHelperText>

            <TextField
              fullWidth
              size="small"
              value={dontConnectCaller}
              onChange={(e) => setDontConnectCaller(e.target.value)}
              onKeyDown={onCallerKeyDown('dontConnectCallers')}
              onBlur={addDontConnectCaller}
              disabled={formState.dontConnectCallers.length >= 10}
              sx={{ '& .MuiOutlinedInput-root': { paddingRight: 0 } }}
              inputProps={{ maxLength: 25 }}
              InputProps={{
                sx: { bgcolor: '#E4ECFC' },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ color: '#A5B5E3' }}
                      disabled={!dontConnectCaller || formState.dontConnectCallers.length >= 10}
                      onClick={addDontConnectCaller}
                    >
                      <AddCircle />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <Stack direction="row" sx={{ flexWrap: 'wrap', minHeight: 36 }}>
              {formState.dontConnectCallers.map(caller => (
                <Chip
                  sx={{ mt: '4px', mr: '4px' }}
                  key={caller}
                  label={caller}
                  onDelete={() => onChipDelete('dontConnectCallers', caller)}
                />
              ))}
            </Stack>

            <Typography mt={1} sx={{ fontSize: '14px', fontWeight: 600 }}>Additional Note</Typography>
            <TextField
              sx={{
                bgcolor: '#E4ECFC',
                borderRadius: '5px',
                mt: 1,
              }}
              InputProps={{ sx: { p: 1 } }}
              inputProps={{ style: { fontSize: '14px' }, maxLength: 250 }}
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              value={formState.additionalNote}
              onChange={e => onChange('additionalNote')(e.target.value)}
            />
            <FormHelperText sx={{ textAlign: 'right', pr: '14px' }}>
              {formState.additionalNote.length + '/250'}
            </FormHelperText>
          </Box>
        </Collapse>


        <Collapse in={showAdvanced && formState.availability === 'unavailable'}>
          <Box>
            <Typography mt={1} sx={{ fontSize: '14px', fontWeight: 600 }}>Connect only the following callers</Typography>
            <FormHelperText
              error={formState.connectCallers.length >= 10}
              sx={{ mt: '-4px' }}
            >
              You can add up to 10 callers
            </FormHelperText>

            <TextField
              fullWidth
              size="small"
              value={connectCaller}
              onChange={(e) => setConnectCaller(e.target.value)}
              onKeyDown={onCallerKeyDown('connectCallers')}
              onBlur={addConnectCaller}
              disabled={formState.connectCallers.length >= 10}
              sx={{ '& .MuiOutlinedInput-root': { paddingRight: 0 } }}
              inputProps={{ maxLength: 25 }}
              InputProps={{
                sx: { bgcolor: '#E4ECFC' },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ color: '#A5B5E3', '&:hover': { color: '#1033A5' } }}
                      disabled={!connectCaller || formState.connectCallers.length >= 10}
                      onClick={addConnectCaller}
                    >
                      <AddCircle />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <Stack direction="row" sx={{ flexWrap: 'wrap', minHeight: 36 }}>
              {formState.connectCallers.map(caller => (
                <Chip
                  sx={{ mt: '4px', mr: '4px' }}
                  key={caller}
                  label={caller}
                  onDelete={() => onChipDelete('connectCallers', caller)}
                />
              ))}
            </Stack>

            <Typography mt={1} sx={{ fontSize: '14px', fontWeight: 600 }}>How would you want us to explain your unavailability?</Typography>
            <TextField
              sx={{ mt: 1 }}
              value={formState.unavailabilityExplanations}
              onChange={(e) => onChange('unavailabilityExplanations')(e.target.value)}
              SelectProps={{
                sx: {
                  backgroundColor: '#E4ECFC',
                  borderColor: '#CBD8F1',
                },
                MenuProps: { sx: { width: 350 } },
              }}
              size="small"
              select
              fullWidth
            >
              {UNAVAILABILITY_OPTIONS.map(option => (
                <MenuItem
                  key={option}
                  value={option}
                >
                  <Typography>{option}</Typography>
                </MenuItem>
              ))}
            </TextField>

            <Collapse in={formState.unavailabilityExplanations === 'Other'}>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                size="small"
                value={formState.unavailabilityExplanationsOther}
                onChange={(e) => onChange('unavailabilityExplanationsOther')(e.target.value)}
                InputProps={{
                  sx: { bgcolor: '#E4ECFC' },
                }}
                placeholder="Enter other explanation"
                FormHelperTextProps={{ sx: { textAlign: 'right' } }}
                helperText={formState.unavailabilityExplanationsOther.length + '/25'}
                error={!!errors.unavailabilityExplanationsOther}
                inputProps={{ maxLength: 25 }}
              />
              {errors.unavailabilityExplanationsOther && <FormHelperText error>{errors.unavailabilityExplanationsOther}</FormHelperText>}
            </Collapse>

            <Typography mt={5} sx={{ fontSize: '14px', fontWeight: 600 }}>Additional Note</Typography>
            <TextField
              sx={{
                bgcolor: '#E4ECFC',
                borderRadius: '5px',
                mt: 1,
              }}
              InputProps={{ sx: { p: 1 } }}
              inputProps={{ style: { fontSize: '14px' }, maxLength: 250 }}
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              value={formState.additionalNote}
              onChange={e => onChange('additionalNote')(e.target.value)}
            />
            <FormHelperText sx={{ textAlign: 'right', pr: '14px' }}>
              {formState.additionalNote.length + '/250'}
            </FormHelperText>
          </Box>
        </Collapse>

        <Stack direction="row" justifyContent="flex-end">
          <AnimatedButton
            onClick={handleSubmit}
            label={isSubmitting ? 'Saving...' : 'Save'}
            loading={isSubmitting}
            disabled={isSubmitting}
            sx={{
              mt: 4,
              width: 130,
              borderRadius: '15px',
            }}
          />
        </Stack>
      </Box>
    </Dialog>
  );
};

export default ContactStatusDialog;
