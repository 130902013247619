import React, { FC, useMemo } from "react";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Email, MergeType, Voicemail } from "@mui/icons-material";
import { Contact, getTransferNumbers } from "utils/accounts";
import Highlighter from 'react-highlight-words';
import CopyButton from "components/buttons/CopyButton";

interface IProps {
  search: string,
  contact: Contact,
}

// Static contact without option to click on numbers
const ContactNumbersStatic: FC<IProps> = ({ contact, search }) => {
  const transferNumbers = useMemo(() => {
    if (!contact) return []

    return getTransferNumbers(contact)
  }, [contact])

  const noBubbleUp = (e: React.MouseEvent<any>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const hideNumbers = !contact.connectOnly && contact.doYouTakeCalls !== 'Yes' && contact.doYouTakeCalls !== 'Emergency Calls Only'
  return (
    <Box width="100%">
      {!hideNumbers && transferNumbers.map(transferNumber => (
        <Stack direction="row" alignItems="center" key={transferNumber.value} flexWrap="wrap">
          <Typography sx={{ fontSize: '13px', whiteSpace: 'nowrap', fontWeight: 600 }}>
            {transferNumber.label}:&nbsp;
          </Typography>
          <Stack direction="row" alignItems="center" key={transferNumber.value} minHeight={21}>
            <Typography
              sx={{ fontSize: '13px', whiteSpace: 'nowrap' }}
              onMouseDown={noBubbleUp}
            >
              <Highlighter
                searchWords={[search.toLowerCase()]}
                autoEscape={true}
                textToHighlight={transferNumber.value}
              />
            </Typography>
            <CopyButton text={transferNumber.value} />
            {transferNumber.type === 'Both' &&
              <Tooltip arrow placement="top" title="Blind Transfer">
                <span>
                  <IconButton sx={{ p: 0, ml: '4px', color: '#D79F2B' }} disabled>
                    <MergeType sx={{ fontSize: '20px' }} />
                  </IconButton>
                </span>
              </Tooltip>
            }
          </Stack>
        </Stack>
      ))}
      {contact.assignedVoicemail &&
        <Stack direction="row" alignItems="center" minHeight={21}>
          <Voicemail sx={{ color: '#F2B648', fontSize: '18px', mr: 1 }} />
          <Typography sx={{ fontSize: '13px', fontWeight: 600 }}>
            VM:&nbsp;
            <span onMouseDown={noBubbleUp}>
              <Highlighter
                searchWords={[search.toLowerCase()]}
                autoEscape={true}
                textToHighlight={contact.assignedVoicemail}
              />
            </span>
          </Typography>
          <CopyButton text={contact.assignedVoicemail} />
          <Tooltip arrow placement="top" title="Blind Transfer">
            <span>
              <IconButton
                sx={{ p: 0, ml: '4px', color: '#D79F2B' }}
                disabled
              >
                <MergeType sx={{ fontSize: '20px' }} />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      }
      {(contact.email && contact.okToGiveOutEmailAddress !== 'No') &&
        <Stack direction="row" alignItems="center">
          <Email sx={{ color: '#2F6FCF', fontSize: '18px', mr: '1px' }} />
          <Typography sx={{ fontSize: '13px' }}>
            :&nbsp;
            <span
              onMouseDown={noBubbleUp}
              style={{ color: '#1033A5', textDecoration: 'underline', cursor: 'pointer' }}
            >
              <Highlighter
                searchWords={[search.toLowerCase()]}
                autoEscape={true}
                textToHighlight={contact.email}
              />
            </span>
          </Typography>
          <CopyButton text={contact.email} />
        </Stack>
      }
    </Box>
  )
}

export default ContactNumbersStatic;
